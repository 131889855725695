<template>
    <div>
        <van-button @click="houseDetailPreMaturity" plain class="my_buttton" type="primary" style="margin-bottom: 0px">房源详情预到期</van-button>
        <van-button @click="houseDetailSublet" plain class="my_buttton" type="primary" style="margin-bottom: 0px">房源详情转租</van-button>
        <van-button @click="houseRelevant" plain class="my_buttton" type="primary" style="margin-bottom: 0px">房源相关</van-button>
		<van-button @click="clearRetreat" plain class="my_buttton" type="primary" style="margin-bottom: 0px">清退</van-button>
		<van-button @click="paymentApproval" plain class="my_buttton" type="primary" style="margin-bottom: 0px">待审核</van-button>
        <van-button @click="userPortrayal" plain class="my_buttton" type="primary" style="margin-bottom: 0px">用户画像</van-button>
        <van-button @click="basicPerson" plain class="my_buttton" type="primary" style="margin-bottom: 0px">基础人事</van-button>
        <van-button @click="statisticsModule" plain class="my_buttton" type="primary" style="margin-bottom: 0px">统计模块</van-button>
        <van-button @click="decorationStatistics" plain class="my_buttton" type="primary" style="margin-bottom: 0px">装修统计</van-button>
        <van-button @click="customerService" plain class="my_buttton" type="primary" style="margin-bottom: 0px">客户服务</van-button>
        <van-button @click="afterRentStatistics" plain class="my_buttton" type="primary" style="margin-bottom: 0px">租后统计</van-button>
        <van-button @click="setMainAnalysis" plain class="my_buttton" type="primary" style="margin-bottom: 0px">集寓分析</van-button>
        <van-button @click="financialManage" plain class="my_buttton" type="primary" style="margin-bottom: 0px">财务模块</van-button>
        <van-button @click="achievementManage" plain class="my_buttton" type="primary" style="margin-bottom: 0px">业绩模块</van-button>
        <van-button @click="businessStatistics" plain class="my_buttton" type="primary" style="margin-bottom: 0px">业务统计</van-button>
        <van-button @click="collection" plain class="my_buttton" type="primary">收藏</van-button>
        <van-button @click="makeAppointment" class="my_buttton" plain type="primary">预约</van-button>
        <!--优惠券页面没看到-->
        <van-button @click="coupon" plain class="my_buttton" type="primary">优惠券</van-button>
        <!--账单页面 没看到-->
        <van-button @click="bill" plain  class="my_buttton" type="primary">账单</van-button>
        <van-button @click="wallet" plain class="my_buttton" type="primary">钱包</van-button>
        <van-button @click="realNemeAuthentication" class="my_buttton" plain type="primary">实名认证</van-button>
        <van-button @click="contract" plain class="my_buttton" type="primary">合同</van-button>
        <van-button @click="intelligentEquipment" class="my_buttton" plain type="primary">智能设备</van-button>
        <!--线上报修点击跳转没有-->
        <van-button @click="maintenance" plain class="my_buttton" type="primary">维修</van-button>
        <!--点击申请保洁没反应-->
        <van-button @click="cleaning" plain class="my_buttton" type="primary">保洁</van-button>
        <!--我的投诉列表页面 没找到-->
        <van-button @click="complaints" plain class="my_buttton" type="primary">投诉</van-button>
        <!--租约变更列表页面 没找到-->
        <van-button @click="leaseChange" plain class="my_buttton" type="primary">租约变更</van-button>
        <van-button @click="recommendResume" plain class="my_buttton" type="primary">推荐简历</van-button>
        <van-button @click="settin" plain class="my_buttton" type="primary">设置</van-button>
        <van-button @click="leave" plain class="my_buttton" type="primary">请假</van-button>
        <van-button @click="wage" plain class="my_buttton" type="primary">工资</van-button>
        <van-button @click="results" plain class="my_buttton" type="primary">业绩</van-button>
        <!--关于海米页面 没找到-->
        <van-button @click="aboutHimi" plain class="my_buttton" type="primary">关于海米</van-button>
      <van-button @click="financialModule" plain class="my_buttton" type="primary">数据统计</van-button>
        <van-button @click="inviteFriends" plain class="my_buttton" type="primary" style="margin-bottom: 0px">推荐有礼</van-button>

        <rxTabbar checked="my"></rxTabbar>
    </div>

</template>

<script>
    import {
        Button, Tabbar, TabbarItem
    }from 'vant'
    import rxTabbar from '../../components/rongxun/rx-tabbar/tabbar'
    export default {
        name: "TemporaryButton",
        components : {
            [Tabbar.name]:Tabbar,
            [TabbarItem.name]:TabbarItem,
            [Button.name] : Button,
            rxTabbar
        },
        data (){
            return{
                active:3,
            }
        },
        mounted() {
            console.log(this.$route.params.referrerId)
        },

        methods : {
          //房源详情转租
          houseDetailSublet() {
            this.$router.push({
              name: 'changeLease',
              query: {
                room_id: '1', // 东特爱俪舍家酒店公寓-华北路（甘）2号1单元2022号
                setHouse_id:'47',
                fromType: '1',
                contractId: '7452',

              }
            })
          },

          //房源详情预到期签约
          houseDetailPreMaturity() {
            this.$router.push({
              name: 'changeLease',
              query: {
                room_id: '2740',  // 天泉山庄-红旗东路73号4单元502号
                setHouse_id:'23',
                fromType: '2',
                contractId: '7178',

              }
            })
          },

          financialModule() {
            this.$router.push({
              name: 'financialModule',
              query: {

              }
            })
          },

            //收藏
            collection() {
                this.$router.push({
                    name: 'myCollection',
                    query: {

                    }
                })
            },
            //清退
            clearRetreat() {
                this.$router.push({
                    name: 'clearRetreat',
                    query: {

                    }
                })
            },
            //待审核
            paymentApproval() {
                this.$router.push({
                    name: 'unPaid',
                    query: {

                    }
                })
            },
            //预约
            makeAppointment() {
                this.$router.push({
                    name: 'myOrder',
                    query: {

                    }
                })
            },
            //优惠券
            coupon() {
                this.$router.push({
                    name: 'myDiscountCoupon',
                    query: {

                    }
                })
            },
            //账单
            bill() {
                this.$router.push({
                    name: 'myBill',
                    query: {

                    }
                })
            },
            //钱包
            wallet() {
                this.$router.push({
                    name: 'wallet',
                    query: {

                    }
                })
            },
            //实名认证
            realNemeAuthentication() {
                this.$router.push({
                    name: 'RealNameAuthentication',
                    query: {

                    }
                })
            },
            //合同
            contract() {
                this.$router.push({
                    name: 'myContract',
                    query: {

                    }
                })
            },
            //智能设备
            intelligentEquipment() {
                this.$router.push({
                    name: 'mySmartDevice',
                    query: {

                    }
                })
            },
            //维修
            maintenance() {
                this.$router.push({
                    name: 'myRepair',
                    query: {

                    }
                })
            },
            //保洁
            cleaning() {
                this.$router.push({
                    name: 'myCleaning',
                    query: {

                    }
                })
            },
            //投诉
            complaints() {
                this.$router.push({
                    name: 'myComplaint',
                    query: {

                    }
                })
            },
            //租约变更
            leaseChange() {
                this.$router.push({
                    name: 'myChangeLease',
                    query: {

                    }
                })
            },
            //推荐简历
            recommendResume() {
                this.$router.push({
                    name: 'RecommendResume',
                    query: {

                    }
                })
            },
            //推荐有礼
            inviteFriends() {
                this.$router.push({
                    name: 'inviteFriends',
                    query: {

                    }
                })
            },
            //用户画像
            userPortrayal() {
                this.$router.push({
                    name: 'userPortrayal',
                    query: {

                    }
                })
            },

            //房源相关
            houseRelevant() {
              this.$router.push({
                name: 'houseRelevant',
                query: {
                  room_id: '48',
                  changeStatus:'',
                  roomType: '8',
                  setHouse_id: '8'
                }
              })
            },

            //基础人事
            basicPerson() {
                this.$router.push({
                    name: 'basepersonnel',
                    query: {
						dataType : 'c',

                    }
                })
            },
            //统计模块
            statisticsModule() {
                this.$router.push({
                    name: 'statisticsModule',
                    query: {
						        dataType : '0',
					}
                })
            },
            //装修统计
            decorationStatistics() {
                this.$router.push({
                    name: 'decorationStatistics',
                    query: {

                    }
                })
            },
            //客户服务
            customerService() {
                this.$router.push({
                    name: 'customerService',
                    query: {

                    }
                })
            },
            //租后统计
            afterRentStatistics() {
                this.$router.push({
                    name: 'afterRentStatistics',
                    query: {

                    }
                })
            },
            //集寓分析
            setMainAnalysis() {
                this.$router.push({
                    name: 'setApartmentStatement',
                    query: {

                    }
                })
            },
            //财务模块
            financialManage() {
                this.$router.push({
                    name: 'financialmoudule',
                    query: {

                    }
                })
            },
            //业绩模块
            achievementManage() {
                this.$router.push({
                    name: 'achievementManage',
                    query: {

                    }
                })
            },
            //业务统计
            businessStatistics() {
                this.$router.push({
                    name: 'businessStatistics',
                    query: {

                    }
                })
            },
            //设置
            settin() {
                this.$router.push({
                    name: 'SetPersonalInfo',
                    query: {

                    }
                })
            },
            //业绩
            results() {
                this.$router.push({
                    name: 'results',
                    query: {

                    }
                })
            },
            //请假
            leave() {
                this.$router.push({
                    name: 'leave',
                    query: {

                    }
                })
            },
            //工资
            wage() {
                this.$router.push({
                    name: 'wage',
                    query: {

                    }
                })
            },
            //关于海米
            aboutHimi() {
                this.$router.push({
                    name: 'AboutHimi',
                    query: {

                    }
                })
            },


        }
    }

</script>

<style scoped>
    .my_buttton{
        width: 45%;
        height: 40px;
        margin-left: 3%;
        margin-top: 30px;
        float: left;
    }
</style>
